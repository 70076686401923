import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import Navegar from '../components/navegar'

export const query = graphql`
  {
    allTipsBalancitaYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const TipsBalancita = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsBalancitaYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtbalancita1balancitajpg')} />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>Balancita para valijas</h2>
                <p>
                  ¡Qué maravilla! Ya no tienes que sufrir más en los aeropuertos pensando cuánto
                  pesará tu valija, ni tendrás que dejar cosas o, pagar ¡150 euros por unos kilos de
                  más! Esta balancita es sencillísima. Enganchas la valija, sostienes la balancita,
                  de la que cuelga la valija, aprietas el botoncito rojo y esperas unos instantes
                  que se estabilice la medida.
                </p>
                <p>
                  ¿No es increíble? Esto te permite distribuir tu equipaje de manera de no pasarte
                  en el peso. Pero...no todo resulta genial!!! Está muy buena para la valija de
                  mano, la de 8 kg.
                </p>
                <p>
                  Para las otras, las de 23 kg o de 25 kg, uhm!!! depende de la persona. A mí me
                  resultó imposible sostener la balancita y la valija por tan solo unos minutos en
                  esa posición, sencillamente, no pude. Hay que tener fuerza para hacerlo. Además es
                  una posición muy incómoda. Lástima que no se pueda colgar de algo.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3 id="accion">Balancita en acción.</h3>
                <p>
                  Balancita portátil para valijas en acción. En este momento la valija "carry-on",
                  la de hasta 8 kg que puede ir en la cabina contigo, está colgando de la balancita
                  y como puedes ver pesa 6,5 kg, estoy salvada, ja, ja.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtbalancita2balancitajpg')} />
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsBalancita
